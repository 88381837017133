.FacebookTags {}

.modelbox {
    position:   relative;
    width:      100%;
    height: 0;
    padding-top:   120%; /* This is your aspect ratio */ }
  
  .model {
    position: absolute;
    top:      0;
    left:     0;
    bottom:   0;
    right:    0;
    width:    100%;
    height:   100%
  }