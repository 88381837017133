.SelectImageDialog {}


.dropzone {
    background: #eee;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 2px dashed cyan;
    outline: none;
  }

