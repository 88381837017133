

.dropzone {
    background: #eee;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border: 2px dashed cyan;
    outline: none;
  }
