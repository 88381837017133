
.ck-editor__editable {
    min-height: 500px;
}


.button_bg{
    background: black;
}


.dropzone {
    background: #eee;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border: 2px dashed cyan;
    outline: none;
  }


  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    overflow-y: auto;
  }
  
  .item {
    display: flex;
      padding: 8px;
      position: relative;
      max-width: 25%;
      flex-basis: 100%;
      border-radius: 2px;
      flex-direction: column;
  }
  
 
  .SortableList {
      position: relative;
      z-index: 0;
      background-color: #F3F3F3;
      border: 1px solid #EFEFEF;
      border-radius: 3px;
      outline: none;
      width: 100%;
      height: auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border: 1px solid #E0E0E0;
      list-style: none;
      padding: 0;
  }
  .SortableItem {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 60px;
      background-color: #FFF;
      border-bottom: 1px solid #EFEFEF;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: #333;
      font-weight: 500;
  }
  .SortableHelper {
      box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
      background-color: rgba(255, 255, 255, 0.8);
      cursor: row-resize;
  }
  
