.VariantsTable {}


.dropzone {
    background: #eee;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    cursor: pointer;
    border: 2px dashed cyan;
    outline: none;
  }


  .container {
    position: relative;
    width: 60px; /* The size you want */
  }
  .container:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }
  
  .container img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
  }